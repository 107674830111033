<mat-accordion class="long-term-contract-location-pair">
  <mat-expansion-panel [expanded]="true" class="p-25">
    <mat-expansion-panel-header>
      <mat-panel-title> {{ title() }} </mat-panel-title>
    </mat-expansion-panel-header>
    <hmt-long-term-contract-location-pair-summary [locationPair]="locationPair()" [serviceId]="serviceId()" />
    @for (vesselScheduleGroup of vesselScheduleGroups(); track vesselScheduleGroup.name) {
      <div class="mt-40">
        <hmt-long-term-location-pair-vessel-schedule-table [vesselScheduleGroup]="vesselScheduleGroup" />
      </div>
      @if (locationPair().hasDrayage) {
        @if (vesselScheduleGroup.shipmentMode === 'FCL') {
          <div class="font-16 bold mb-25 mt-30">
            Drayage Fee:
            {{ locationPair().drayageType | statusToLower: ' ' | capitalize }}
          </div>
          <div
            *ngFor="let charge of locationPair().drayageCharges.loadRates; track: charge"
            class="additional-charge-row">
            <mat-form-field appearance="outline" class="charge-type-field" subscriptSizing="dynamic">
              <mat-label>Drayage Charges</mat-label>
              <input type="text" matInput [readonly]="true" [value]="charge.description" />
            </mat-form-field>
            <div class="amount-currency-pair">
              <mat-form-field appearance="outline" class="amount-field" subscriptSizing="dynamic">
                <mat-label>Amount</mat-label>
                <input matInput type="number" [readonly]="true" [value]="charge.amount" />
              </mat-form-field>
              <mat-form-field appearance="outline" class="currency-dropdown" subscriptSizing="dynamic">
                <mat-label>Currency</mat-label>
                <input matInput readonly [value]="charge.currencyCode" [readonly]="true" />
              </mat-form-field>
            </div>
          </div>
        }
        @if (vesselScheduleGroup.shipmentMode === 'LCL') {
          <div class="font-16 bold mb-10 mt-30">
            Drayage Fee:
            {{ locationPair().drayageType | statusToLower: ' ' | capitalize }}
          </div>
          <div class="row">
            <hmt-lcl-charging-structure-v2
              [isVisible]="true"
              [isReadOnly]="true"
              [isAirFreight]="false"
              [inputRates]="locationPair().drayageCharges.rates.values"
              [inputWeights]="locationPair().drayageCharges.volumeWeights.values"></hmt-lcl-charging-structure-v2>
          </div>
        }
      }
    }

    @if (flightScheduleGroups()) {
      <div class="mt-40">
        <hmt-long-term-location-pair-flight-schedule-table [flightScheduleGroup]="flightScheduleGroups()" />
      </div>
      @if (locationPair().hasDrayage) {
        <div class="font-16 bold mb-10 mt-30">
          Drayage Fee:
          {{ locationPair().drayageType | statusToLower: ' ' | capitalize }}
        </div>
        <div class="row">
          <hmt-lcl-charging-structure-v2
            [isAirFreight]="true"
            [isVisible]="true"
            [isReadOnly]="true"
            [inputRates]="locationPair().drayageCharges.rates.values"
            [inputWeights]="locationPair().drayageCharges.volumeWeights.values"></hmt-lcl-charging-structure-v2>
        </div>
      }
    }
    @if (allAdditionalCharges().length > 0) {
      <h3 class="mt-30 mb-25">Fees</h3>
      <div *ngFor="let charge of allAdditionalCharges(); let i = index" class="additional-charge-row">
        <mat-form-field appearance="outline" class="charge-type-field" subscriptSizing="dynamic">
          <mat-label>Additional Charge</mat-label>
          <input type="text" matInput [readonly]="true" [value]="charge.description" />
        </mat-form-field>
        <div class="amount-currency-pair">
          <mat-form-field appearance="outline" class="amount-field" subscriptSizing="dynamic">
            <mat-label>Amount</mat-label>
            <input matInput type="number" [readonly]="true" [value]="charge.amount" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="currency-dropdown" subscriptSizing="dynamic">
            <mat-label>Currency</mat-label>
            <input matInput readonly [value]="charge.currencyCode" [readonly]="true" />
          </mat-form-field>
        </div>
      </div>
    } @else {
      <p class="no-fees-message">No Additional Fees</p>
    }
    @if (locationPair().remarks && htmlToPlainText(locationPair().remarks).length > 0) {
      <div class="fee-remarks mt-10">
        <hmt-rich-text-editor
          labelStyles="font-size: 1.17em; font-weight: bold;"
          [boldLabel]="true"
          [label]="'Remarks'"
          [outputString]="locationPair().remarks"
          [disabled]="true"></hmt-rich-text-editor>
      </div>
    }
  </mat-expansion-panel>
</mat-accordion>
