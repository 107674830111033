export enum ContractStatusValue {
  CONTRACTED = 'Contracted',
  IN_PROGRESS = 'In Progress',
  INVOICE_PENDING = 'Invoice Approval Pending',
  TERMINATED = 'Contract Terminated',
  JOB_COMPLETED = 'Job Completed',
  INVOICE_DISPUTED = 'Invoice Disputed',
  INVOICE_REJECTED = 'Invoice Rejected',
  INVOICE_APPROVED = 'Invoice Approved',
  COMPLETED = 'Completed',
}
