import { Pipe, PipeTransform } from '@angular/core';
import { ContractStatusValue } from 'app/modules/contract-management/enums/contract-status-value.enum';

@Pipe({
  name: 'contractStatus',
  pure: true, // ensures the pipe only recalculates when inputs change
})
export class ContractStatusPipe implements PipeTransform {
  transform(value: string): string {
    return ContractStatusValue[value] || value;
  }
}
