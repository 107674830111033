import { inject, Injectable, signal } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Organization } from 'app/modules/organizations-manager/models/organization.model';
import {
  GET_ORGANIZATION_BY_ID_QUERY,
  GetOrganizationByIdDTO,
} from 'app/modules/organizations-manager/queries/get-organization-by-id.query';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrgDetailsService {
  apollo = inject(Apollo);

  orgDetails = signal<Record<string, { organization: Organization; timestamp: number; query: string }>>({});
  private pendingRequests = new Map<string, Promise<Organization>>();

  async getOrgDetails(orgId: string, query?: string) {
    const cacheKey = `${orgId}-${query || 'default'}`;
    const cachedOrgDetails = this.orgDetails()[cacheKey];
    const now = Date.now();
    const FIFTY_NINE_MINUTES = 59 * 60 * 1000;

    if (cachedOrgDetails && now - cachedOrgDetails.timestamp < FIFTY_NINE_MINUTES) {
      return cachedOrgDetails.organization;
    }

    // Check if there's already a pending request for this orgId and query
    if (this.pendingRequests.has(cacheKey)) {
      return await this.pendingRequests.get(cacheKey);
    }

    // Create new request and store it
    const request = firstValueFrom(
      this.apollo.query<GetOrganizationByIdDTO>({
        query: query
          ? gql`
              ${query}
            `
          : GET_ORGANIZATION_BY_ID_QUERY,
        variables: { orgId },
      })
    ).then(result => result.data.getOrganizationById);

    this.pendingRequests.set(cacheKey, request);

    try {
      const organization = await request;
      this.orgDetails.set({
        ...this.orgDetails(),
        [cacheKey]: {
          organization,
          timestamp: now,
          query: query || 'default',
        },
      });

      return organization;
    } finally {
      // Clean up the pending request
      this.pendingRequests.delete(cacheKey);
    }
  }
}
