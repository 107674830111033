<mat-form-field class="chip-list" appearance="outline">
  @if (label && label.length > 0) {
    <mat-label class="uppercase">{{ label }}</mat-label>
  }
  <input
    matInput
    [placeholder]="placeholder"
    #selectedItemInput
    [formControl]="formControl"
    [matAutocomplete]="autoComplete" />
  <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="selectItem($event.option.value)">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      {{ item?.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-chip *ngFor="let item of selectedItems" [removable]="removable" (removed)="remove(item)" class="chip">
  {{ item?.name }}
  <mat-icon matChipRemove *ngIf="removable" class="cancel">cancel</mat-icon>
</mat-chip>
