<div class="principle-contacts-container">
  <div class="header-container">
    <div class="header">
      <p>PRINCIPLE CONTACT</p>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div class="form" formGroupName="principalContacts">
      <div class="left-column">
        <mat-form-field class="first-name" appearance="outline">
          <mat-label>First Name*</mat-label>
          <input type="text" matInput formControlName="firstName" placeholder="First Name*" class="first-name-value" />
          <mat-error *ngIf="formGroup.get('principalContacts.firstName')?.hasError('required')">
            First Name is required.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="division" appearance="outline">
          <mat-label>Division</mat-label>
          <input type="text" matInput formControlName="division" placeholder="Division" class="division-value" />
        </mat-form-field>
        <mat-form-field class="nic" appearance="outline">
          <mat-label>NIC</mat-label>
          <input type="text" matInput formControlName="nic" placeholder="NIC" class="nic-value" />
          <mat-error *ngIf="formGroup.get('principalContacts.nic')?.hasError('pattern')"> NIC is invalid. </mat-error>
        </mat-form-field>
        <mat-form-field class="email" appearance="outline">
          <mat-label>Email</mat-label>
          <input type="text" matInput formControlName="email" placeholder="Email" class="email-value" />
        </mat-form-field>
        <mat-form-field class="faxNumber" appearance="outline">
          <mat-label>Fax Number</mat-label>
          <input type="text" matInput formControlName="faxNumber" placeholder="Fax Number" class="faxNumber-value" />
        </mat-form-field>
      </div>
      <div class="mid-flex-grow"></div>
      <div class="right-column">
        <mat-form-field class="last-name" appearance="outline">
          <mat-label>Last Name*</mat-label>
          <input type="text" matInput formControlName="lastName" placeholder="Last Name*" class="last-name-value" />
          <mat-error *ngIf="formGroup.get('principalContacts.lastName')?.hasError('required')">
            Last Name is required.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="designation" appearance="outline">
          <mat-label>Designation</mat-label>
          <input
            type="text"
            matInput
            formControlName="designation"
            placeholder="Designation"
            class="designation-value" />
        </mat-form-field>
        <mat-form-field class="mobile-number" appearance="outline">
          <mat-label>Mobile Number</mat-label>
          <input
            type="text"
            matInput
            formControlName="mobileContact"
            placeholder="Office Mobile Number"
            class="mobile-number-value" />
        </mat-form-field>
        <div class="contact-office" formGroupName="officeContact">
          <mat-form-field class="value" appearance="outline">
            <mat-label>Office Phone Number</mat-label>
            <input
              type="text"
              matInput
              formControlName="phoneNumber"
              placeholder="Office Phone Number"
              class="state-value" />
          </mat-form-field>
          <mat-form-field class="ext" appearance="outline">
            <mat-label>Ext</mat-label>
            <input type="text" matInput formControlName="ext" placeholder="Ext" class="state-value" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
