<div class="registered-address-container">
  <div class="header-container">
    <div class="header">
      <p>REGISTERED ADDRESS</p>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div class="form" formGroupName="registeredAddress">
      <mat-form-field class="lane" appearance="outline">
        <mat-label>Lane</mat-label>
        <input type="text" matInput formControlName="lane" placeholder="Lane" class="lane-value" />
      </mat-form-field>

      <mat-form-field class="city" appearance="outline">
        <mat-label>City</mat-label>
        <input type="text" matInput formControlName="city" placeholder="City" class="city-value" />
      </mat-form-field>

      <mat-form-field class="country" appearance="outline">
        <mat-label>Country*</mat-label>
        <input
          type="text"
          matInput
          formControlName="country"
          placeholder="Country*"
          [matAutocomplete]="countryAuto"
          class="country-value"
          (keyup)="searchCountry($event)"
          (click)="onClickCountry()" />
        <div>
          <i class="material-icons align-arrow-right">arrow_drop_down</i>
        </div>
        <mat-autocomplete #countryAuto="matAutocomplete" (optionSelected)="onSelectCountry()">
          <mat-option class="select-label" *ngFor="let country of filteredCountriesList" [value]="country.countryName">
            {{ country.countryName }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="formGroup.get('registeredAddress.country')?.hasError('required')">
          Country is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="state" appearance="outline">
        <mat-label>State*</mat-label>
        <input
          type="text"
          matInput
          formControlName="state"
          placeholder="State*"
          [matAutocomplete]="stateAuto"
          class="state-value"
          (keyup)="searchState($event)"
          (click)="onClickState()" />
        <div>
          <i class="material-icons align-arrow-right">arrow_drop_down</i>
        </div>
        <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="onSelectState()">
          <ng-container *ngFor="let country of countries">
            <ng-container *ngIf="country.countryName === formGroup.get('registeredAddress').get('country').value">
              <mat-option class="select-label" *ngFor="let state of filteredStatesList" [value]="state">
                {{ state }}
              </mat-option>
            </ng-container>
          </ng-container>
        </mat-autocomplete>
        <mat-error *ngIf="formGroup.get('registeredAddress.state')?.hasError('required')">
          State is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="zipCode" appearance="outline">
        <mat-label>Zip Code</mat-label>
        <input type="text" matInput formControlName="zipCode" placeholder="Zip Code" class="zipCode-value" />
      </mat-form-field>

      <mat-form-field class="timezone" appearance="outline">
        <mat-label>Time Zone</mat-label>
        <input
          type="text"
          matInput
          formControlName="timeZone"
          placeholder="Time Zone*"
          [matAutocomplete]="timezoneAuto"
          class="timezone-value"
          (keyup)="searchTimeZone($event)" />
        <div>
          <i class="material-icons align-arrow-right">arrow_drop_down</i>
        </div>
        <mat-autocomplete #timezoneAuto="matAutocomplete" (optionSelected)="onClickTimeZone($event.option.value)">
          <mat-option class="select-label" *ngFor="let timezone of filteredTimezonesList" [value]="timezone.label">
            {{ timezone.label }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="formGroup.get('registeredAddress.timeZone')?.hasError('required')">
          Time Zone is required.
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
