import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { JsonSchemaService } from 'app/json-schema-forms/services/json-schema.service';
import { htmlToPureText } from 'app/utils/html-to-pure-text.util';
import {} from 'ngx-editor/schema';
@Component({
  selector: 'hmt-formly-rich-text-editor',
  templateUrl: './formly-rich-text-editor.component.html',
  styleUrls: ['./formly-rich-text-editor.component.scss'],
})
export class FormlyRichTextEditorComponent extends FieldWrapper implements OnInit {
  jsonSchemaService = inject(JsonSchemaService);

  outputString = signal<string>(null);
  disabled = signal<boolean>(false);
  label = signal<string>(null);

  isOutputStringAvailable = computed(() => {
    return this.outputString() && htmlToPureText(this.outputString()).trim() !== '';
  });

  ngOnInit(): void {
    this.outputString.set(this.props['outputString']);
    this.disabled.set(this.props['disabled']);
    this.label.set(this.props['label']);
  }

  get styleObject() {
    return this.jsonSchemaService.cssStringToObject(this.props['styles'] || '');
  }
}
