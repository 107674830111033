import { DatePipe, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, inject, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { PreSignedUrl } from '@shared/models/pre-signed-url.model';
import { FileHandlerService } from '@shared/services/file-handler.service';
import { Downloader } from 'ag-grid-community';
import { first } from 'rxjs';
import { UploadedFileType } from '../../../modules/wizard/models/uploaded-file.type';
import { OrgDocumentPreviewDialogComponent } from '../org-document-preview-dialog/org-document-preview-dialog.component';

@Component({
  selector: 'hmt-uploaded-org-document',
  templateUrl: './uploaded-org-document.component.html',
  styleUrl: './uploaded-org-document.component.scss',
  standalone: true,
  imports: [FormsModule, MatSlideToggle, NgIf, NgOptimizedImage, MatButtonModule, MatIconModule, DatePipe],
})
export class UploadedOrgDocumentComponent {
  readonly dialogService = inject(DialogHandlerService);
  readonly fileHandlerService = inject(FileHandlerService);
  readonly notificationService = inject(NotificationService);

  file = input.required<UploadedFileType>();
  showViewBtn = input<boolean>(true);
  showDownloadBtn = input<boolean>(true);
  presignedUrl = signal<PreSignedUrl | null>(null);

  deleteButtonClicked = output<UploadedFileType>();

  onViewBtnClicked() {
    this.dialogService.openDialog(OrgDocumentPreviewDialogComponent, { file: this.file() });
  }

  onDeleteBtnClicked() {
    this.deleteButtonClicked.emit(this.file());
  }

  onDownloadBtnClicked() {
    this.fileHandlerService
      .fetchFile(this.file().fileId)
      .pipe(first())
      .subscribe({
        next: async presignedUrl => {
          this.presignedUrl.set(presignedUrl);
          const urlOfFile = presignedUrl.url;
          const blob = await (await fetch(urlOfFile)).blob();
          Downloader.download(this.file().fileName, blob);
        },
        error: () => {
          this.notificationService.showError('Failed to download file');
        },
      });
  }
}
