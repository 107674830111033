import { Component, computed, input } from '@angular/core';
import { LongTermRatesContract } from 'app/modules/contract-management/models/long-term-contract.model';
import { htmlToPureText } from 'app/utils/html-to-pure-text.util';

@Component({
  selector: 'hmt-long-term-contract-location-pair',
  templateUrl: './long-term-contract-location-pair.component.html',
  styleUrls: ['./long-term-contract-location-pair.component.scss'],
})
export class LongTermContractLocationPairComponent {
  locationPair =
    input.required<LongTermRatesContract['metaData']['serviceMetaData'][number]['locationPairs'][number]>();

  index = input.required<number>();
  serviceId = input.required<string>();

  titlePrefix = computed(() => {
    switch (this.serviceId()) {
      case 'OCEAN-FREIGHT':
        return 'Port Pair';
      case 'AIR-FREIGHT':
        return 'Airport Pair';
      default:
        return 'Location Pair';
    }
  });

  title = computed(() => {
    return `${this.titlePrefix()} ${this.index() + 1}`;
  });

  vesselScheduleGroups = computed(() => {
    if (!this.locationPair().vesselsScheduleReferenceGroup) {
      return [];
    }

    return this.locationPair().vesselsScheduleReferenceGroup.map(group => ({
      name: `Vessel Schedule - ${group.shipmentMode}`,
      carrierReferences: group.carrierReference,
      shipmentMode: group.shipmentMode,
    }));
  });

  flightScheduleGroups = computed(() => {
    if (!this.locationPair().flightScheduleReferenceGroup) {
      return null;
    }

    return {
      name: `Flight Schedule - ${this.locationPair().flightScheduleReferenceGroup.shipmentMode}`,
      carrierReferences: this.locationPair().flightScheduleReferenceGroup.carrierReference,
      shipmentMode: this.locationPair().flightScheduleReferenceGroup.shipmentMode,
    };
  });

  baseAdditionalCharges = computed(() => {
    return this.locationPair().additionalCharges;
  });

  allAdditionalCharges = computed(() => {
    return [...this.baseAdditionalCharges()];
  });

  htmlToPlainText(html: string): string {
    return htmlToPureText(html).trim();
  }
}
